import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/home/Home.vue";
import Music from "./components/music/Music.vue";
import Games from "./components/game/Games.vue"
import Live from "./components/live/Live.vue"
import RunRunJumpPunch from "./components/runrunjumppunch/RunRunJumpPunch.vue"
import Contact from "./components/contact/Contact.vue"
import GamePage from "./components/game/GamePage"
import ReleasePage from "./components/music/ReleasePage"
import WhenWeWereFree from "./components/shortcuts/WhenWeWereFree"
import Hibernation from "./components/shortcuts/Hibernation"
import Baluchon from "./components/shortcuts/Baluchon"
import Wakey from "./components/shortcuts/Wakey"
import Libellula from "./components/shortcuts/Libellula"
import Epk from "./components/shortcuts/Epk"
import Tuto from "./components/tuto/Tuto"
import ChiptuneTuto from "./components/tuto/ChiptuneTuto"
import BitsyTuto from "./components/tuto/BitsyTuto"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/music",
    name: "Music",
    component: Music
  },
  {
    path: "/games",
    name: "Games",
    component: Games
  },
  {
    path: "/live",
    name: "Live",
    component: Live
  },
  {
    path: "/rrjp",
    name: "RunRunJumpPunch",
    component: RunRunJumpPunch
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/game/:id",
    name: "Game",
    component: GamePage,
    props: true,
  },
  {
    path: "/release/:type/:id",
    name: "Release",
    component: ReleasePage,
    props: true
  },
  {
    path: "/wwwf",
    name: "WhenWeWereFree",
    component: WhenWeWereFree,
  },
  {
    path: "/hibernation",
    name: "Hibernation",
    component: Hibernation,
  },
  {
    path: "/baluchon",
    name: "Baluchon",
    component: Baluchon,
  },
  {
    path: "/wakey",
    name: "Wakey",
    component: Wakey,
  },
  {
    path: "/libellula",
    name: "Libellula",
    component: Libellula
  },
  {
    path: "/epk",
    name: "EPK",
    component: Epk,
  },
  {
    path: "/tuto",
    name: "Tuto",
    component: Tuto,
  },
  {
    path: "/tuto/chiptune",
    name: "ChiptuneTuto",
    component: ChiptuneTuto,
  },
  {
    path: "/tuto/bitsy",
    name: "BitsyTuto",
    component: BitsyTuto,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;